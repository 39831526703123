<template>
  <div class="front-cont f-page-bg pt-3 pb-4">
    <div class="row px-3 px-md-5 px-lg-6 pt-5 pt-md-0">
      <div
        v-for="(item, index) in Pagination.Items"
        :key="item.AssessmentServiceKey"
        class="col-md-6 col-lg-4 col-xl-3 mt-3 px-md-1"
      >
        <el-card style="height: 100%" class="c-pointer" @click="viewService(item)">
          <el-image
            v-if="item.ServicePicture"
            class="service-img mb-2" alt="..." 
            fit="contain"
            :src="$axios.formulateUrl(`/image/view/tmp/${item.ServicePicture}`)"
          />

          <div class="pt-1 pb-2">
            <h3
              :class="{
                'f-text-orange': index % 3 == 0,
                'f-text-blue': index % 3 == 1,
                'f-text-green': index % 3 == 2,
              }"
            >
              {{ $t.translate(`FP_${item.ServiceCatgCode}`) }}
            </h3>

            <h4 aria-label="tags">
              <span
                v-for="(tag, tagIndex) in $h.renderTags(item.Tags)"
                :key="'tag-' + tagIndex"
                class="f-text-gray mr-1"
                >#{{ $h.formatName(tag, "Name") }}</span
              >
            </h4>

            <h2 class="mb-0">
              {{ $h.formatServiceName(item) }}
            </h2>
          </div>
        </el-card>
      </div>

      <div v-if="!Pagination.Items.length" class="col-md-12 px-md-2 no-data-cont">
        <el-alert
          :title="$t.translate('LBL_NO_RECORD_FOUND')"
          show-icon
          :closable="false"
          class="my-5"
        ></el-alert>
      </div>

      <div class="col-md-12">
        <div class="row mt-4">
          <div class="col-md-6 mb-1 px-md-2 d-flex">
            <div class="d-flex align-items-center f-bd-blue f-bg-ser-pagi px-3">
              <div class="f-text-blue cus-ser-entry">{{ $t.translate("LBL_SHOW") }}</div>
              <el-select
                :placeholder="$t.translate('FRM_SELECT')"
                @change="loadItems(1)"
                v-model="Pagination.PageSize"
                class="general-select cus-ser-entry"
                style="width: 70px"
                popper-class="front-popper"
              >
                <el-option
                  v-for="item in $configs.pageSizeOptions"
                  :label="$t.translate(item.label)"
                  :key="item.value"
                  :value="item.value"
                />
              </el-select>

              <div class="f-text-blue ml-2 cus-ser-entry">
                {{ $t.translate("LBL_ENTRIES") }}
              </div>
            </div>
          </div>

          <div
            v-if="Pagination.Items.length"
            class="col-md-6 px-0 d-flex justify-content-md-end pl-3 pl-md-0 pr-2"
          >
            <el-pagination
              v-model:current-page="Pagination.CurrentPage"
              :layout="
                mq.current == 'xsmall' ? 'prev, next, jumper' : 'prev, pager, next'
              "
              :total="Pagination.ItemsTotal"
              :pager-count="5"
              :page-size="Pagination.PageSize"
              :prev-text="$t.translate('BTN_PREV')"
              :next-text="$t.translate('BTN_NEXT')"
              class="f-gen-pagination f-bd-blue custome-service-page f-bg-ser-pagi"
              @current-change="loadItems"
              role="" 
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, onUpdated, ref, reactive, computed } from "vue";
import { useRoute } from "vue-router";
import { post, get, isValidResponse } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { router } from "@/utils/router";
import { extendedConfigs } from "@/utils/configs";
import { useMq } from "vue3-mq";

export default {
  setup() {
    const mq = useMq();
    const route = useRoute();
    let { defaultSize } = extendedConfigs;

    let Pagination = reactive({
      Items: [],
      ItemsTotal: 0,
      PageSize: defaultSize,
      CurrentPage: 1,
    });

    let formData = reactive({
      Keyword: route?.query?.s || "",
      ServiceCatgCode: route?.query?.type || "",
    });

    onMounted(async () => {
      loadItems(1);
    });

    onUpdated(() => {
      let filter = route?.query?.type || "";
      if (filter == formData.ServiceCatgCode) return;
      formData.ServiceCatgCode = filter;

      loadItems(1);
    });

    const loadItems = async (page) => {
      if (page) {
        Pagination.CurrentPage = page;
      }

      let resp = await get("/public/service", {
        ...formData,
        SortBy: "LastCreated",
        SortOrder: "descending",
        page: Pagination.CurrentPage,
        size: Pagination.PageSize,
      });

      Pagination.ItemsTotal = resp?.totalData;
      Pagination.Items = resp?.data || [];
    };

    const viewService = async (row) => {
      router.changeRoute("/service/view/" + row.AssessmentServiceKey);
    };

    return { mq, Pagination, formData, loadItems, viewService };
  },
};
</script>
<style>
.service-img {
  width: 100%;
  /*height: 150px;*/
  object-fit: cover;
}

.f-bg-ser-pagi {
  background: #FEFEFE
}

.custome-service-page button > span {
  font-size: var(--f-size-cus-ser-btn) !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  color: #0A6CFF;
}
.cus-ser-entry,
.general-select.cus-ser-entry .el-input__inner {
  font-size: var(--f-size-cus-ser-btn);
}

.custome-service-page .el-pager li {
  color: #5d5d5d;
}

.custome-service-page .el-pager li.active {
  color: #0A6CFF;
}

.custome-service-page button:disabled > span {
  color: #ccc;
}

.no-data-cont {
  min-height: 60vh;
}
@media (max-width: 768px) {
  .no-data-cont {
    min-height: 55vh;
  }
}
</style>
